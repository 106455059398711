import resolveConfig from 'tailwindcss/resolveConfig';
import config from '~/tailwind.config';
const {theme} = resolveConfig(config);

import classNames from 'classnames';
import register from 'preact-custom-element';

const colorMap: Record<string, string> = {
  'primary-green': 'text-primary-green',
  'primary-blue': 'text-primary-blue',
  'primary-teal': 'text-primary-teal',
  'logo-lime': 'text-logo-lime',
  'accent-red': 'text-accent-red',
  'neutral-blue-gray': 'text-neutral-blue-gray',
  'bright-white': 'text-bright-white',
  'fresh-scent': 'text-fresh-scent',
};

/**
 * Animated loading spinner component.
 * Takes a `color` prop that can be one of the following:
 * - `primary-green`
 * - `primary-blue`
 * - `primary-teal`
 * - `logo-lime`
 * - `accent-red`
 * - `neutral-blue-gray`
 * - `bright-white`
 * - `primary-gradient`
 *
 * These values map to brand colors/gradients.
 */
export default function LoadingIndicator({
  color = 'green',
  size = 32,
}: LoadingIndicatorProps) {
  const strokeColor = colorMap[color] ?? 'text-primary-green';

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      className={classNames('loader inline-block', strokeColor)}
    >
      <defs>
        <linearGradient id="spin-gradient" x1="0%" x2="100%" y1="0%" y2="100%">
          <stop
            // The stop-color attribute is valid here, `stopColor` will not work.
            // eslint-disable-next-line react/no-unknown-property
            stop-color={theme.colors['primary-teal'].DEFAULT}
            offset="12.65%"
          ></stop>
          <stop
            // eslint-disable-next-line react/no-unknown-property
            stop-color={theme.colors['primary-green'].DEFAULT}
            offset="86.53%"
          ></stop>
        </linearGradient>
      </defs>
      <circle
        cx="50"
        cy="50"
        r="43"
        fill="none"
        // eslint-disable-next-line react/no-unknown-property
        stroke-width="0.875rem"
        // eslint-disable-next-line react/no-unknown-property
        stroke-linecap="round"
        stroke={
          color === 'primary-gradient' ? 'url(#spin-gradient)' : 'currentColor'
        }
      ></circle>
    </svg>
  );
}

type LoadingIndicatorProps = {
  color: string;
  size?: number;
};

register(LoadingIndicator, 'loading-indicator', ['color']);
